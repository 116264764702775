var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pipedrive-page"},[_c('div',{staticClass:"pipedrive-page__inner"},[_c('v-sheet',{staticClass:"pa-8",attrs:{"rounded":"lg","color":"white"}},[_c('h1',{staticClass:"text-h5 font-weight-medium"},[_vm._v(" Konfiguracja połączenia ze skrzynką email ")]),_c('div',[_vm._v(" Po podaniu danych zostanie przeprowadzony test połączenia, a następnie pobrane zostaną wszystkie nieodczytane wiadomości. ")]),_c('v-form',{ref:"form",staticClass:"mt-8",on:{"submit":function($event){$event.preventDefault();return _vm.setApiKey.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"mb-2",attrs:{"label":"Adres serwera IMAP poczty","outlined":"","rules":[
                        function (v) {
                            if(!v || v == ''){
                                return 'To pole jest wymagane';
                            }
                            return true;
                        }
                    ]},model:{value:(_vm.server),callback:function ($$v) {_vm.server=$$v},expression:"server"}}),_c('v-select',{staticClass:"mb-2",attrs:{"outlined":"","label":"Port","hint":"Użyj portu 993, jeżeli chcesz ustanowić bezpieczne, szyfrowane połączenie (serwer pocztowy musi wspierać taki typ połączenia)","persistent-hint":"","items":[143, 993]},model:{value:(_vm.port),callback:function ($$v) {_vm.port=$$v},expression:"port"}}),_c('v-text-field',{staticClass:"mb-2",attrs:{"label":"Nazwa użytkownika","outlined":"","rules":[
                        function (v) {
                            if(!v || v == ''){
                                return 'To pole jest wymagane';
                            }
                            return true;
                        }
                    ]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-text-field',{attrs:{"label":"Hasło","outlined":"","type":_vm.password_visible ? 'text' : 'password',"rules":[
                        function (v) {
                            if(!v || v == ''){
                                return 'To pole jest wymagane';
                            }
                            return true;
                        }
                    ],"append-icon":_vm.password_visible ? 'mdi-eye-off' : 'mdi-eye'},on:{"click:append":function($event){_vm.password_visible = !_vm.password_visible}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"success","block":"","disabled":_vm.loading,"large":""},on:{"click":_vm.fetchEmails}},[_vm._v(" "+_vm._s(_vm.loading ? 'Trwa łączenie i pobieranie wiadomości...' : 'Połącz ze skrzynką e-mail')+" ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }