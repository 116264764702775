<template>
    <div class="pipedrive-page">
        <div class="pipedrive-page__inner">
            <v-sheet
                rounded="lg"
                color="white"
                class="pa-8"
            >
                <h1 class="text-h5 font-weight-medium">
                    Konfiguracja połączenia z Pipedrive
                </h1>
                <div>
                    Krok 1: Klucz API
                </div>
                <v-form class="mt-8" ref="form" @submit.prevent="setApiKey">
                    <v-text-field 
                        label="Klucz API Pipedrive"
                        outlined
                        v-model="api_key"
                        hint="Podany klucz musi należeć do konta z uprawnieniami Administratora"
                        persistent-hint
                        :rules="[
                            v => {
                                if(!v || v == ''){
                                    return 'To pole jest wymagane';
                                }
                                if(!(/^[A-Za-z0-9]{40}$/).test(v)){
                                    return 'Proszę podać poprawny klucz API';
                                }
                                return true;
                            }
                        ]"
                    />
                </v-form>

                <v-btn
                    color="success"
                    block
                    class="mt-2"
                    :disabled="loading"
                    @click="setApiKey"
                >
                    {{ loading ? 'Trwa weryfikacja połączenia...' : 'Połącz z Pipedrive' }}
                </v-btn>
            </v-sheet>
        </div>
    </div>
</template>

<script>
export default {
    
    data(){
        return {
            api_key: '',
            loading: false
        }
    },

    methods: {
        async setApiKey(){
            if(this.loading || !this.$refs.form.validate(true)) return;
            this.loading = true;

            try{

                await this.axios.post("/pipedrive/set-api-key", {
                    api_key: this.api_key
                });
                
                this.$store.state.PIPEDRIVE_API_KEY_SET = true;
                this.$store.state.PIPEDRIVE_API_KEY = this.api_key;
                if(window.sessionStorage){
                    sessionStorage.setItem('LS_PIPEDRIVE_AK', this.api_key);
                }

                this.$router.push('/pipedrive-settings');

            }
            catch(err){
                console.error(err);

                this.$store.dispatch('addMessage', {
                    type: 'error',
                    msg: err.response.data.msg
                });
            }

            this.loading = false;
        }
    }
}
</script>