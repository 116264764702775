var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pipedrive-page"},[_c('div',{staticClass:"pipedrive-page__inner"},[_c('v-sheet',{staticClass:"pa-8",attrs:{"rounded":"lg","color":"white"}},[_c('h1',{staticClass:"text-h5 font-weight-medium"},[_vm._v(" Konfiguracja połączenia z Pipedrive ")]),_c('div',[_vm._v(" Krok 1: Destynacja leadów ")]),_c('v-form',{ref:"form",staticClass:"mt-8",on:{"submit":function($event){$event.preventDefault();return _vm.setPipedriveData.apply(null, arguments)}}},[_c('v-select',{attrs:{"outlined":"","label":"Docelowy pipeline","disabled":!_vm.pipelines_fetched,"items":_vm.pipelines,"item-value":"id","item-text":"name","rules":[
                        function (v) {
                            if(!v || v == ''){
                                return 'Proszę wybrać docelowy pipeline';
                            }
                            return true;
                        }
                    ]},on:{"change":function (_) {
                        _vm.selected_stage = '';
                        _vm.fetchStages();
                    }},model:{value:(_vm.selected_pipeline),callback:function ($$v) {_vm.selected_pipeline=$$v},expression:"selected_pipeline"}}),_c('v-select',{attrs:{"outlined":"","label":"Docelowy stage","disabled":!_vm.stages_fetched,"items":_vm.stages,"item-value":"id","item-text":"name","rules":[
                        function (v) {
                            if(!v || v == ''){
                                return 'Proszę wybrać docelowy stage';
                            }
                            return true;
                        }
                    ]},model:{value:(_vm.selected_stage),callback:function ($$v) {_vm.selected_stage=$$v},expression:"selected_stage"}}),_c('v-alert',{attrs:{"dense":"","text":"","type":"info"}},[_c('div',{staticClass:"text-body-2"},[_vm._v(" Aktualnie dostępne do wyboru są tylko niektóre typy dodatkowych pól leadów ")])]),_c('v-select',{attrs:{"outlined":"","multiple":"","label":"Ustaw dodatkowe pola pobranym leadom","disabled":!_vm.deal_fields_fetched,"items":_vm.deal_fields,"item-value":"key","item-text":"name"},on:{"change":_vm.prepareDF},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption ml-1"},[_vm._v(" (+"+_vm._s(_vm.selected_deal_fields.length - 1)+" inne) ")]):_vm._e()]}}]),model:{value:(_vm.selected_deal_fields),callback:function ($$v) {_vm.selected_deal_fields=$$v},expression:"selected_deal_fields"}}),(_vm.prepared_df.length > 0)?_c('div',{staticClass:"pb-8"},[_c('div',{staticClass:"text-body-1 font-weight-bold"},[_vm._v(" Wartości wybranych pól ")]),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Pole ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Wartość ")])])]),_c('tbody',_vm._l((_vm.prepared_df),function(item){return _c('tr',{key:item.key},[_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[(item.field_type == 'enum')?_c('v-select',{staticClass:"mt-6",attrs:{"label":"Wybierz opcję","dense":"","outlined":"","items":item.options,"item-text":"label","item-value":"id","rules":[
                                                function (v) {
                                                    if(!v || v == ''){
                                                        return 'Wybierz jakąs opcję';
                                                    }
                                                    return true;
                                                }
                                            ]},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):_c('v-textarea',{staticClass:"mt-6",attrs:{"label":"","dense":"","outlined":"","rows":1,"auto-grow":"","rules":[
                                                function (v) {
                                                    if(!v || v == ''){
                                                        return 'Uzupełnij to pole';
                                                    }
                                                    return true;
                                                }
                                            ]},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1)])}),0)]},proxy:true}],null,false,132518854)})],1):_vm._e()],1),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"success","block":"","loading":_vm.loading},on:{"click":_vm.setPipedriveData}},[_vm._v(" Zapisz ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }