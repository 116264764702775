<template>
    <div class="pipedrive-page">
        <div class="pipedrive-page__inner">
            <v-sheet
                rounded="lg"
                color="white"
                class="pa-8"
            >
                <h1 class="text-h5 font-weight-medium">
                    Żródło leadów
                </h1>
                <div>
                    Wybierz skąd mają zostać zaimportowane leady
                </div>

                <v-row :gutter="32" class="mt-4">
                    <v-col cols="6">
                        <v-btn
                            color="primary"
                            block
                            @click="setMode('email')"
                            large
                        >
                            <v-icon>mdi-email</v-icon>
                            <span class="ml-1">Z konta e-mail</span>
                        </v-btn>

                    </v-col>
                    <v-col cols="6">
                        
                        <v-btn
                            color="secondary"
                            block
                            :disabled="true"
                            @click="setMode('file')"
                            large
                        >
                            <v-icon>mdi-file</v-icon>
                            <span class="ml-1">Z pliku</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-sheet>
        </div>
    </div>
</template>

<script>
export default {
    
    data(){
        return {
        }
    },

    methods: {
        async setMode(mode){
            this.$store.state.IMPORT_MODE = mode;
            if(mode == 'email'){
                this.$router.push('/email-setup');
            }
            else{
                this.$router.push('/import-file');
            }
        }
    }
}
</script>