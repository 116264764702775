<template>
    <div class="pipedrive-page">
        <div class="pipedrive-page__inner">
            <v-sheet
                rounded="lg"
                color="white"
                class="pa-8"
            >
                <h1 class="text-h5 font-weight-medium">
                    Konfiguracja połączenia ze skrzynką email
                </h1>
                <div>
                    Po podaniu danych zostanie przeprowadzony test połączenia, a następnie pobrane zostaną wszystkie nieodczytane wiadomości.
                </div>
                <v-form class="mt-8" ref="form" @submit.prevent="setApiKey">
                    <v-text-field 
                        label="Adres serwera IMAP poczty"
                        outlined
                        v-model="server"
                        :rules="[
                            v => {
                                if(!v || v == ''){
                                    return 'To pole jest wymagane';
                                }
                                return true;
                            }
                        ]"
                        class="mb-2"
                    />

                    <v-select 
                        outlined
                        label="Port"
                        hint="Użyj portu 993, jeżeli chcesz ustanowić bezpieczne, szyfrowane połączenie (serwer pocztowy musi wspierać taki typ połączenia)"
                        persistent-hint
                        :items="[143, 993]"
                        v-model="port"
                        class="mb-2"
                    />

                    <v-text-field 
                        label="Nazwa użytkownika"
                        outlined
                        v-model="username"
                        :rules="[
                            v => {
                                if(!v || v == ''){
                                    return 'To pole jest wymagane';
                                }
                                return true;
                            }
                        ]"
                        class="mb-2"
                    />

                    <v-text-field 
                        label="Hasło"
                        outlined
                        :type="password_visible ? 'text' : 'password'"
                        v-model="password"
                        :rules="[
                            v => {
                                if(!v || v == ''){
                                    return 'To pole jest wymagane';
                                }
                                return true;
                            }
                        ]"
                        :append-icon="password_visible ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="password_visible = !password_visible"
                    />
                </v-form>

                <v-btn
                    color="success"
                    block
                    class="mt-2"
                    :disabled="loading"
                    @click="fetchEmails"
                    large
                >
                    {{ loading ? 'Trwa łączenie i pobieranie wiadomości...' : 'Połącz ze skrzynką e-mail' }}
                </v-btn>
            </v-sheet>
        </div>
    </div>
</template>

<script>
export default {
    
    data(){
        return {
            server: '',
            port: 993,
            username: '',
            password: '',
            loading: false,

            password_visible: false
        }
    },

    methods: {
        async fetchEmails(){
            if(this.loading || !this.$refs.form.validate(true)) return;
            this.loading = true;

            try{

                const email_data = await this.axios.post("/mail/fetch-emails", {
                    username: this.username,
                    password: this.password,
                    server: this.server,
                    port: this.port
                });
                
                // 1. robimy kopię do sessionStorage tych wiadomości
                if(window.sessionStorage){
                    sessionStorage.setItem('LS_LEADS_UNFILTERED', JSON.stringify(email_data.data.messages));
                }

                // 2. dodajemy te wiadomości do store
                this.$store.state.LEADS_UNFILTERED = email_data.data.messages;

                // 3. krok następny
                this.$router.push("/leads-filtering");

            }
            catch(err){
                console.error(err);

                this.$store.dispatch('addMessage', {
                    type: 'error',
                    msg: err.response.data.msg
                });
            }

            this.loading = false;
        }
    }
}
</script>