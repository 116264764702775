<template>
    <div class="pipedrive-page">
        <div class="pipedrive-page__inner">
            <v-sheet
                rounded="lg"
                color="white"
                class="pa-8"
            >
                <h1 class="text-h5 font-weight-medium">
                    Konfiguracja połączenia z Pipedrive
                </h1>
                <div>
                    Krok 1: Destynacja leadów
                </div>
                <v-form class="mt-8" ref="form" @submit.prevent="setPipedriveData">
                    
                    <v-select
                        outlined
                        label="Docelowy pipeline"
                        :disabled="!pipelines_fetched"
                        :items="pipelines"
                        item-value="id"
                        item-text="name"
                        v-model="selected_pipeline"
                        @change="_ => {
                            selected_stage = '';
                            fetchStages();
                        }"
                        :rules="[
                            v => {
                                if(!v || v == ''){
                                    return 'Proszę wybrać docelowy pipeline';
                                }
                                return true;
                            }
                        ]"
                    />

                    <v-select
                        outlined
                        label="Docelowy stage"
                        :disabled="!stages_fetched"
                        :items="stages"
                        item-value="id"
                        item-text="name"
                        v-model="selected_stage"
                        :rules="[
                            v => {
                                if(!v || v == ''){
                                    return 'Proszę wybrać docelowy stage';
                                }
                                return true;
                            }
                        ]"
                    />

                    <v-alert
                        dense
                        text
                        type="info"
                    >
                        <div class="text-body-2">
                            Aktualnie dostępne do wyboru są tylko niektóre typy dodatkowych pól leadów
                        </div>
                    </v-alert>

                    <v-select
                        outlined
                        multiple
                        label="Ustaw dodatkowe pola pobranym leadom"
                        :disabled="!deal_fields_fetched"
                        :items="deal_fields"
                        item-value="key"
                        item-text="name"
                        v-model="selected_deal_fields"
                        @change="prepareDF"
                    >
                        <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0">{{ item.name }}</span>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption ml-1"
                            >
                                (+{{ selected_deal_fields.length - 1 }} inne)
                            </span>
                        </template>
                    </v-select>

                    <div class="pb-8" v-if="prepared_df.length > 0">
                        <div class="text-body-1 font-weight-bold">
                            Wartości wybranych pól
                        </div>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Pole
                                        </th>
                                        <th class="text-left">
                                            Wartość
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="item in prepared_df"
                                        :key="item.key"
                                    >
                                        <td>
                                            {{ item.name }}
                                        </td>
                                        <td>
                                            <v-select 
                                                v-if="item.field_type == 'enum'"
                                                label="Wybierz opcję"
                                                dense
                                                outlined
                                                class="mt-6"
                                                :items="item.options"
                                                item-text="label"
                                                item-value="id"
                                                v-model="item.value"
                                                :rules="[
                                                    v => {
                                                        if(!v || v == ''){
                                                            return 'Wybierz jakąs opcję';
                                                        }
                                                        return true;
                                                    }
                                                ]"
                                            />
                                            <v-textarea 
                                                v-else
                                                label=""
                                                dense
                                                outlined
                                                class="mt-6"
                                                v-model="item.value"
                                                :rows="1"
                                                auto-grow
                                                :rules="[
                                                    v => {
                                                        if(!v || v == ''){
                                                            return 'Uzupełnij to pole';
                                                        }
                                                        return true;
                                                    }
                                                ]"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>

                </v-form>

                <v-btn
                    color="success"
                    block
                    class="mt-2"
                    :loading="loading"
                    @click="setPipedriveData"
                >
                    Zapisz
                </v-btn>
            </v-sheet>
        </div>
    </div>
</template>

<script>
export default {
    
    data(){
        return {
            api_key: '',
            loading: false,

            pipelines_fetched: false,
            pipelines: [],
            selected_pipeline: '',

            stages_fetched: false,
            stages: [],
            selected_stage: '',

            deal_fields_fetched: false,
            deal_fields: [],
            selected_deal_fields: [], 

            ALLOWED_FIELD_TYPES: [
                'enum', 'varchar', 'double', 'text', 'int'
            ],

            prepared_df: []
        } 
    },

    methods: {

        prepareDF(){
            const cp = JSON.parse(JSON.stringify(this.prepared_df));
            this.prepared_df = [];
            for(let i = 0; i < this.selected_deal_fields.length; i++){

                const DF = this.deal_fields.find(item => item.key == this.selected_deal_fields[i]);
                if(!DF) continue;

                const ix = cp.findIndex(item => item.key == DF.key);
                // console.log(cp)
                if(ix !== -1){
                    this.prepared_df.push({
                        key: DF.key,
                        name: DF.name,
                        field_type: DF.field_type,
                        options: DF.options || [],
                        value: cp[ix].value
                    });
                }
                else{
                    this.prepared_df.push({
                        key: DF.key,
                        name: DF.name,
                        field_type: DF.field_type,
                        options: DF.options || [],
                        value: ''
                    });
                }

            }
        },

        async setPipedriveData(){
            if(this.loading || !this.$refs.form.validate(true)) return;
            this.loading = true;

            try{

                await this.axios.post("/pipedrive/settings", {
                    pipeline: this.selected_pipeline,
                    stage: this.selected_stage,
                    deal_fields: this.prepared_df
                });
                
                this.$store.state.PIPEDRIVE_SELECTED_PIPELINE = this.selected_pipeline;
                this.$store.state.PIPEDRIVE_SELECTED_STAGE = this.selected_stage;
                this.$store.state.PIPEDRIVE_SELECTED_DEAL_FIELDS = this.selected_deal_fields;
                this.$store.state.PIPEDRIVE_SETTINGS_SET = true;

                if(window.sessionStorage){
                    sessionStorage.setItem('LS_SELECTED_PIPELINE', this.selected_pipeline);
                    sessionStorage.setItem('LS_SELECTED_STAGE', this.selected_stage);
                    sessionStorage.setItem('LS_SELECTED_DEAL_FIELDS', JSON.stringify(this.prepared_df));
                }

                this.$router.push('/leads-source');

            }
            catch(err){
                console.error(err);

                this.$store.dispatch('addMessage', {
                    type: 'error',
                    msg: err.response.data.msg
                });
            }

            this.loading = false;
        },

        async fetchPipelines(){
            try{

                const r = await this.axios.get("/pipedrive/pipelines");
                this.pipelines = r.data.pipelines;
                this.pipelines_fetched = true;
                
            }
            catch(err){ console.error(err) }
        },
        async fetchStages(){
            try{

                const r = await this.axios.get("/pipedrive/stages/?pipeline=" + this.selected_pipeline);
                this.stages = r.data.stages;
                this.stages_fetched = true;

            }
            catch(err){ console.error(err) }
        },
        async fetchDealFields(){
            try{

                const r = await this.axios.get("/pipedrive/deal-fields");
                this.deal_fields = r.data.deal_fields.filter(
                    item => this.ALLOWED_FIELD_TYPES.indexOf(item.field_type) !== -1
                    && (/^[a-fA-F0-9]{40}$/).test(item.key)
                );
                this.deal_fields_fetched = true;

                // console.log(this.deal_fields); 

            }
            catch(err){ console.error(err) }
        }
    },

    mounted(){
        this.fetchPipelines();
        this.fetchDealFields();
    }
}
</script>