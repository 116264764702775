var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pipedrive-page"},[_c('div',{staticClass:"pipedrive-page__inner"},[_c('v-sheet',{staticClass:"pa-8",attrs:{"rounded":"lg","color":"white"}},[_c('h1',{staticClass:"text-h5 font-weight-medium"},[_vm._v(" Konfiguracja połączenia z Pipedrive ")]),_c('div',[_vm._v(" Krok 1: Klucz API ")]),_c('v-form',{ref:"form",staticClass:"mt-8",on:{"submit":function($event){$event.preventDefault();return _vm.setApiKey.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"label":"Klucz API Pipedrive","outlined":"","hint":"Podany klucz musi należeć do konta z uprawnieniami Administratora","persistent-hint":"","rules":[
                        function (v) {
                            if(!v || v == ''){
                                return 'To pole jest wymagane';
                            }
                            if(!(/^[A-Za-z0-9]{40}$/).test(v)){
                                return 'Proszę podać poprawny klucz API';
                            }
                            return true;
                        }
                    ]},model:{value:(_vm.api_key),callback:function ($$v) {_vm.api_key=$$v},expression:"api_key"}})],1),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"success","block":"","disabled":_vm.loading},on:{"click":_vm.setApiKey}},[_vm._v(" "+_vm._s(_vm.loading ? 'Trwa weryfikacja połączenia...' : 'Połącz z Pipedrive')+" ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }